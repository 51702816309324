import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function ChemistryCardPara(props) {
	return (
		<Typography
			variant="body1"
			align="left"
			color="textSecondary"
			component="p">
			{props.data}
		</Typography>
	);
}
