import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Toolbar, InputBase, Divider, Paper, Button, FormControl, Select, MenuItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
		fontSize: 16,
		width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}))(InputBase);

const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
		marginBottom: '10px'
	},
	root: {
		width: '100%',
		maxWidth: 1000,
	},
	search: {
		color: 'white',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		borderRadius: theme.shape.borderRadius,
		// backgroundColor: fade(theme.palette.common.white, 0.15),
		// '&:hover': {
		// 	backgroundColor: fade(theme.palette.common.white, 0.25)
		// },
		marginRight: 'auto',
		marginLeft: 'auto !important',
		marginTop: '5px',
		marginBottom: '10px',
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: '100%'
		},
	},
	searchIcon: {
		height: '100%',
		margin: 'auto 5px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
		flexGrow: 3,
	},
	inputInput: {
		color: 'grey',
		background: 'white',
		textAlign: 'left',
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '100%'
		}
	},
	toolbar: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		maxWidth: 1000,
	},
	icon: {
		marginTop: '10px',
		marginBottom: '5px',
		backgroundColor: '#ffffff26',
		color: '#fff'
	},
	divider: {
    height: 28,
		margin: 4,
		width: 1,
  },
}));

export default function HomePageSearch(props) {
	const classes = useStyles();
	const [searchParam, setSearch] = useState(props.searchParam || '');
	const [categoryFilter, setCategory] = useState(props.themeid || 0);
	const [isGoToSearchPage, goToSearchPage] = useState(false);

	const handleChange = e => {
		setSearch(e.target.value);
		props.search(e);
	};

	const handleChangeCategory = e => {
		setCategory(e.target.value);
		if (props.onThemeChange) {
			props.onThemeChange(e.target.value);
		}
	};

	const clickSearchButton = e => {
		e.preventDefault();
    goToSearchPage(true)
	};

	return (
		<Toolbar className={classes.toolbar}>
			{
				isGoToSearchPage ? (
					<Redirect
						to={{
							pathname: `/articleList/${categoryFilter|| ''}`,
							search: `search=${searchParam}`
						}}
					/>
				) : ''
			}
			<Paper className={classes.search}>
				<InputBase
					onChange={handleChange}
					placeholder="Enter beverage name..."
					value={searchParam ? searchParam : ''}
					type="text"
					classes={{
						root: classes.inputRoot,
						input: classes.inputInput
					}}
					inputProps={{ 'aria-label': 'search' }}
				/>
				<Divider className={classes.divider} orientation="vertical" />
				<FormControl className={classes.margin}>
					<Select
						labelId="demo-customized-select-label"
						id="demo-customized-select"
						value={categoryFilter}
						onChange={handleChangeCategory}
						input={<BootstrapInput />}
					>
						<MenuItem value={0}>
							<em>All categories</em>
						</MenuItem>
						<MenuItem value={7}>Juice</MenuItem>
						<MenuItem value={8}>Instant drink</MenuItem>
						<MenuItem value={9}>Tea</MenuItem>
						<MenuItem value={10}>Soft drink</MenuItem>
						<MenuItem value={11}>Energy drink</MenuItem>
						<MenuItem value={12}>Dairy products</MenuItem>
					</Select>
				</FormControl>
				<Button className={classes.searchIcon} onClick={clickSearchButton}>
					<SearchIcon />
				</Button>
			</Paper>
		</Toolbar>
	);
}
