import React, { useState, useEffect } from 'react';
import axios from 'axios';

import ChemistryAppBar from '../components/AppBar';
import ChemistryCard from '../components/Cards/Card';
import ChemistryTags from '../components/Tags';
import HomePageFooter from '../components/HomePage/HomeFooter';
import { Hidden } from '@material-ui/core';

function Dashboard({ match }) {
	const [data, setData] = useState({});

	let popularTags = ['energy free', 'sugar free', 'very low salt', 'high vitamin C', 'low energy', 'low salt', 'low sugar', 'high calcium'];

	useEffect(() => {
		async function fetchData() {
			const result = await axios.get(
				`${process.env.REACT_APP_API_URL}/v1/articles/${match.params.id}`
			);
			setData(result.data);
			document.title = `${result.data.title} - Nutrient Information - funded by OUHK`;
		}
		fetchData();
	}, [match.params.id]);

	return (
		<div className='Dashboard'>
			<ChemistryAppBar data={data} />
			<ChemistryTags data={data.tags} />
			<ChemistryCard data={data} />
			<Hidden xsDown implementation='js'>
				<HomePageFooter popularTags={popularTags} />
			</Hidden>
		</div>
	);
}

export default Dashboard;
