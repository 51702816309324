import React, { useState, useEffect } from "react";

import "./HomePageFooter.css";

function HomePageFooter(props) {
	const [popularTags, setPopularTags] = useState(null);

	useEffect(() => {
	function handlePopularTags(popularTags) {
		setPopularTags(props.popularTags);
	}
	handlePopularTags(props.popularTags);
	});

	return (
		<div className="HomePageFooter">
			<div className="FooterContainer">
				<div className="FooterRow">
					<div className="FooterMenu">
						<h5 className="white-text">Links</h5>
						<ul>
							<li>
								<a className="grey-text text-lighten-3" href="/">
									Home
								</a>
							</li>
						</ul>
					</div>
					<div className="FooterCategory">
						<h5 className="white-text">Categories</h5>
						<ul>
							<li>
								<a className="grey-text text-lighten-3" href="/articleList/7">
									Juice
								</a>
							</li>
							<li>
								<a className="grey-text text-lighten-3" href="/articleList/8">
									Instant drink
								</a>
							</li>
							<li>
								<a className="grey-text text-lighten-3" href="/articleList/9">
									Tea
								</a>
							</li>
							<li>
								<a className="grey-text text-lighten-3" href="/articleList/10">
									Soft drink
								</a>
							</li>
							<li>
								<a className="grey-text text-lighten-3" href="/articleList/11">
									Energy drink
								</a>
							</li>
							<li>
								<a className="grey-text text-lighten-3" href="/articleList/12">
									Dairy Products
								</a>
							</li>
						</ul>
					</div>
					<div className="FooterTags">
						<h5 className="white-text">Popular Tags</h5>
						<ul>
							{popularTags
								? popularTags.map((tag, id) => {
										return (
											<li key={id}>
												<a
													className="grey-text text-lighten-3"
													key={id}
													href={`/articleList/?search=${tag}`}
												>
													{tag}
												</a>
											</li>
										);
								  })
								: ""}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default HomePageFooter;
