import React from "react";

import { Popper, Fade, Paper, ClickAwayListener } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import HomePageSearch from "../SelectSearch";
import ChemistryList from "../List";

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		maxWidth: 1000,
	},
}));

export default function ResultsPopper({ data }) {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [searchParam, setSearch] = React.useState("");
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = event => {
		setSearch(event.target.value);
		setAnchorEl(event.currentTarget);
	};

	let open = Boolean(anchorEl);
	const id = open ? "simple-popper" : undefined;

	return (
		<div class={classes.root}>
			<HomePageSearch search={e => handleClick(e)} />
			<Popper
				//  anchorOrigin={{
				// 	vertical: 'bottom',
				// 	horizontal: 'center',
				//   }}
				//   transformOrigin={{
				// 	vertical: 'top',
				// 	horizontal: 'center',
				//   }}
				placement="bottom"
				disablePortal={false}
				modifiers={{
					flip: {
						enabled: false
					},
					preventOverflow: {
						enabled: false,
						boundariesElement: "scrollParent"
					}
				}}
				id={id}
				open={open}
				anchorEl={anchorEl}
				transition
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<div>
									<ChemistryList
										data={data}
										searchWord={searchParam.toLowerCase()}
									/>
								</div>
							</ClickAwayListener>
						</Paper>
					</Fade>
				)}
			</Popper>
		</div>
	);
}
