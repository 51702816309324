import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

import _ from 'lodash';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import ChemistryList from '../components/List';
import ChemistrySearchBar from '../components/SearchBar';
import ChemistryArticleGrid from '../components/articleGrid';
import HomePageFooter from '../components/HomePage/HomeFooter';

const useStyles = makeStyles(theme => ({
	link: {
		textDecoration: 'none',
		color: 'inherit',
		margin: '10px'
	},
	offset: {
		minHeight: 140
	},
	mobileoffset: {
		minHeight: 60
	},
}));

const themeName = [
	{
		title: 'Juice',
		id: 7
	},
	{
		title: 'Instant drink',
		id: 8
	},
	{
		title: 'Tea',
		id: 9
	},
	{
		title: 'Soft drink',
		id: 10
	},
	{
		title: 'Energy drink',
		id: 11
	},
	{
		title: 'Diary products',
		id: 12
	}
];

function ArticleList({ location, match }) {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	let params = new URLSearchParams(location.search);
	const classes = useStyles();
	const [data, setData] = useState([]);
	const [searchParam, setSearch] = useState(params.get('search') || '');
	const [themeid, setTheme] = useState(match.params.id || 0);
	const [isEmptyResult, setIsEmpty] = useState(false);
	let page = 0;
	let tempdata = [];
	let isEmpty = false;
	async function fetchData(themeid, q) {
		const params = {
			limit: 20,
		};
		if (themeid) {
			params.theme = themeid;
		}
		if (q) {
			params.q = q;
		}
		if (page) {
			params.offset = page * 20;
		}
		const url = new URL(window.location);
		url.searchParams.set('search', q || '');
		url.pathname = '/articleList/' + (themeid || '');
		window.history.replaceState(null, null, url.href);
		const result = await axios.get(
			`${process.env.REACT_APP_API_URL}/v1/articles`,
			{
				params,
			}
		);
		if (!result.data) {
			setIsEmpty(true);
			isEmpty = true;
			return;
		} else if (result.data.length < 20) {
			setIsEmpty(true);
			isEmpty = true;
		} else {
			setIsEmpty(false);
			isEmpty = false;
		}
		if (page) {
			// second page
			setData(tempdata.concat(result.data));
			tempdata = tempdata.concat(result.data);
			return;
		} else {
			// fist page
			setData(result.data);
			tempdata = result.data;
			// back to top
			window.scrollTo(0, 0);
		}
	}

	let popularTags = ['energy free', 'sugar free', 'very low salt', 'high vitamin C', 'low energy', 'low salt', 'low sugar', 'high calcium'];

	const fetchDataThrottle = _.throttle(fetchData, 1000, {
		leading: false,
	})

	const isBottom = (el) => {
		return el.getBoundingClientRect().bottom <= window.innerHeight + 300;
	}

	const trackScrolling = () => {
		const wrappedElement = document.body;
		if (isBottom(wrappedElement)) {
			if (!isEmpty) {
				page = page + 1;
				fetchDataThrottle(themeid, searchParam);
			}
		}
	};

	const trackScrollingDedounce = _.debounce(trackScrolling, 800).bind(this);

	useEffect(() => {
		document.title = 'Nutrient Information Inquiry - funded by OUHK';
		page = 0;
		fetchDataThrottle(themeid, searchParam);
		document.addEventListener('scroll', trackScrollingDedounce);
		return function cleanup() {
			document.removeEventListener('scroll', trackScrollingDedounce);
		}
	}, [searchParam, match, themeid]);

	const handleSearch = searchValue => {
		setSearch(searchValue);
	};

	const handleThemeChange = themeid => {
		setTheme(themeid);
	};


	const handleSearchDebouce = _.debounce(handleSearch, 500);

	return (
		<div className={classes.paper}>
			<ChemistrySearchBar
				searchParams={params.get('search')}
				themeName={themeName[themeid - 7]}
				themeid={themeid}
				location={location}
				search={searchValue => handleSearchDebouce(searchValue)}
				onThemeChange={themeid => handleThemeChange(themeid)}
			/>
			{matches ? (
				<div className={classes.mobileoffset} />
			) : (
				<div className={classes.offset} />
			)}
			{matches ? (
				<ChemistryList searchWord={''} data={data} />
			) : (
				<ChemistryArticleGrid searchWord={''} data={data} />
			)}
			{
				!isEmptyResult ? (<LinearProgress variant="query" />) : ''
			}
			{matches ? '': (
				<Hidden smDown implementation='js'>
					<HomePageFooter popularTags={popularTags} />
				</Hidden>
			)}
		</div>
	);
}

export default ArticleList;
