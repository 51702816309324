import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardMedia, CardContent } from '@material-ui/core';

import ChemistryCardImage from './CardImage';
import ChemistryCardPre from './CardText';
import ChemistryCardVideo from './CardVideo';
import ChemistryCardPara from './CardParagraph';
import ChemistryCardHeader from './CardHeader';
import ChemistryCardTable from './CardTable';

const useStyles = makeStyles(theme => ({
	card: {
		maxWidth: 600,
		margin: 'auto',
		marginTop: 10,
		marginBottom: '20px',
	},
	media: {
		height: 0,
		paddingTop: '56.25%',
		backgroundSize: 'contain' // 16:9
	}
}));

export default function ChemistryCard(props) {
	const classes = useStyles();
	const [data, setData] = useState({
		title: '',
		image: '',
		content: {},
		brand: ''
	});

	const [visible, setVisible] = useState(false);

	const makeVisible = () => {
		setVisible(true);
	};

	const disableVisible = () => {
		setVisible(false);
	};

	const images = [props.data.image];

	useEffect(() => {
		props.data.id
			? setData({
					title: props.data.title ? props.data.title : '',
					image: props.data.image,
					content: props.data.content,
					brand: props.data.brand
			  })
			: console.log('No data');
	}, [
		props.data.content,
		props.data.id,
		props.data.image,
		props.data.title,
		props.data.brand
	]);

	return (
		<Card className={classes.card}>
			<CardHeader title={data.title} subheader={data.brand} />
			<CardMedia
				onClick={makeVisible}
				className={classes.media}
				image={data.image}
			/>
			{visible && (
				<Lightbox mainSrc={images[0]} onCloseRequest={disableVisible} />
			)}
			<CardContent>
				{data.content[0]
					? data.content.map((content, id) => {
							return content.type === 'paragraph' ? (
								<ChemistryCardPara key={id} data={content.content} />
							) : content.type === 'header' ? (
								<ChemistryCardHeader key={id} data={content.content} />
							) : content.type === 'preformatted' ? (
								<ChemistryCardPre key={id} data={content.content} />
							) : content.type === 'image' ? (
								<ChemistryCardImage key={id} data={content.content} />
							) : content.type === 'video' ? (
								<ChemistryCardVideo key={id} data={content.content} />
							) : content.type === 'table' ? (
								<ChemistryCardTable key={id} data={content.content} />
							) : (
								''
							);
					  })
					: ''}
			</CardContent>
		</Card>
	);
}
