import React from "react";
import { Hidden } from "@material-ui/core";

import "./HomePageHeader.css";
import ResultsPopper from "./ResultsPopper";

import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	aboutButton: {
		color: 'white'
	}
}));

function HomePageHeader({ data }) {
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
    setOpen(true);
	};
	const handleClose = () => {
    setOpen(false);
	};

	return (
		<div className="HomePageHeader">
			<Hidden xsDown implementation="css">
				<ul className="HomePageNav">
					<li>
						<a href="/">Home</a>
					</li>
					<li>
						<Button className={classes.aboutButton} onClick={handleClickOpen}>
							Acknowledgement
						</Button>
					</li>
				</ul>
			</Hidden>
			<div className="HomePageHero">
				<div className="HomePageIcon">
					<div id="MainLogo">Nutrient Information Inquiry</div>
				</div>
				<ResultsPopper data={data} />
				<Dialog
					aria-labelledby="alert-dialog-title"
					open={open}
					onClose={handleClose}
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">Acknowledgement</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							This database was financially supported by the Research Grant from The Open University of Hong Kong (R6281).
						</DialogContentText>
					</DialogContent>
				</Dialog>
			</div>
		</div>
	);
}

export default HomePageHeader;
