import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function ChemistryCardPre(props) {
	return (
		<Typography
			align="left"
			dangerouslySetInnerHTML={{ __html: props.data }}
			variant="body2"
			color="textSecondary"
			component="p"
		/>
	);
}
