import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GridList, GridListTile, GridListTileBar } from "@material-ui/core";
import dairyImage from "../homepage_images/dairy.jpg";
import energyDrinksImage from "../homepage_images/energy_drinks.jpg";
import juicesImage from "../homepage_images/juices.jpg";
import instantDrinksImage from "../homepage_images/instant_drinks.jpg";
import softDrinkImage from "../homepage_images/soft_drinks.jpg";
import teaImage from "../homepage_images/teas.jpg";

const useStyles = makeStyles(theme => ({
	root: {
		margin: "10px auto",
		maxWidth: "936px",
		border: "1px solid #e6e6e6",
		borderRadius: "4px",
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-around",
		overflow: "hidden",
		backgroundColor: theme.palette.background.paper
	},
	gridList: {
		width: "90%",
		height: "auto",
		marginBottom: "20px !important",
		marginTop: "10px !important",
		justifyContent: "center",
		overflow: "hidden !important"
	},
	icon: {
		color: "rgba(255, 255, 255, 0.54)"
	},
	gridImage: {
		width: "auto",
		height: "inherit"
	},
	personalRoot: {
		cursor: "pointer",
		flexGrow: 1,
		flexBasis: "30%",
		[theme.breakpoints.down("md")]: {
			flexBasis: "50%"
		}
	}
}));

const tileData = [
	{
		title: "Juice",
		id: 7,
		image: juicesImage
	},
	{
		title: "Instant drink",
		id: 8,
		image: instantDrinksImage
	},
	{
		title: "Tea",
		id: 9,
		image: teaImage
	},
	{
		title: "Soft drink",
		id: 10,
		image: softDrinkImage
	},
	{
		title: "Energy drink",
		id: 11,
		image: energyDrinksImage
	},
	{
		title: "Dairy products",
		id: 12,
		image: dairyImage
	}
];
export default function HomePageCategory({ history }) {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<GridList cellHeight={180} className={classes.gridList}>
				{tileData.map(tile => (
					<GridListTile
						component="a"
						href={`/articleList/${tile.id}`}
						classes={{
							tile: classes.personalTile,
							root: classes.personalRoot
						}}
						key={tile.title}
					>
						<img
							className={classes.gridImage}
							alt={tile.title}
							src={tile.image}
						/>
						<GridListTileBar title={tile.title} />
					</GridListTile>
				))}
			</GridList>
		</div>
	);
}
