import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Card, CardMedia, Typography } from '@material-ui/core';

import YoutubeVideoCard from './YoutubeVideoCard';

const useStyles = makeStyles(theme => ({
	card: {
		maxWidth: 600,
		margin: 'auto',
		marginTop: 10
	}
}));

export default function ChemistryCardVideo(props) {
	const classes = useStyles();
	const [data, setData] = useState({
		youtube: false,
		videoID: '',
		url: '',
		caption: ''
	});

	useEffect(() => {
		// eslint-disable-next-line
		const videoIDRegEx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
		const isYoutube = props.data.url.match(videoIDRegEx);
		if (isYoutube) {
			const videoID = props.data.url.split('/')[3];
			setData({
				youtube: true,
				videoID,
				caption: props.data.caption
			});
		} else {
			setData({
				youtube: false,
				url: props.data.url,
				caption: props.data.caption
			});
		}
	}, [props.data.caption, props.data.url]);
	return (
		<Card className={classes.card}>
			{data.youtube ? (
				<YoutubeVideoCard data={data} />
			) : (
				<>
					<CardMedia
						className={classes.media}
						controls
						// autoPlay
						component="video"
						src={data.url}
						// title={data.caption}
					/>
					<Typography
						gutterBottom
						variant="body2"
						color="textSecondary"
						component="p">
						{data.caption}
					</Typography>
				</>
			)}
		</Card>
	);
}
