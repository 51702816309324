import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, Typography } from '@material-ui/core';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const useStyles = makeStyles(theme => ({
	card: {
		maxWidth: 600,
		margin: 'auto',
		marginTop: 10
	},
	media: {
		height: 0,
		paddingTop: '56.25%',
		backgroundSize: 'contain !important' // 16:9
	}
}));

export default function ChemistryCardImage(props) {
	const [visible, setVisible] = useState(false);

	const makeVisible = () => {
		setVisible(true);
	};

	const disableVisible = () => {
		setVisible(false);
	};

	const images = [props.data.url];

	const classes = useStyles();
	return (
		<Card className={classes.card}>
			<CardMedia
				className={classes.media}
				image={props.data.url}
				title={props.data.caption}
				onClick={makeVisible}
			/>
			{visible && (
				<Lightbox mainSrc={images[0]} onCloseRequest={disableVisible} />
			)}
			<Typography
				gutterBottom
				variant="body2"
				color="textSecondary"
				component="p">
				{props.data.caption}
			</Typography>
		</Card>
	);
}
