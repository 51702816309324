import React from 'react';
import { Routers } from './config/routes';

import './App.css';

function App() {
	return (
		<div className="App">
			<Routers />
		</div>
	);
}

export default App;
