import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	AppBar,
	Toolbar,
	Typography,
	Tooltip,
	IconButton,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	backIcon: {
		color: '#fff'
	},
	toolBar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	navBar: {
		backgroundColor: '#333'
	},
	icon: {
		marginTop: '10px',
		marginBottom: '5px !important'
	},
	search: {
		margin: 'auto',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#d32323',
		width: 'auto',
		marginBottom: '10px !important'
	},
	searchIcon: {
		width: theme.spacing(9),
		height: '100%',
		margin: 'auto 0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	articleName: {
		backgroundColor: 'white',
		color: 'black',
		margin: 'auto',
		padding: '10px'
	},
	aboutButton: {
		color: 'white',
	}
}));

export default function ChemistryAppBar(props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
    setOpen(true);
	};
	const handleClose = () => {
    setOpen(false);
  };
	return (
		<div className={classes.root}>
			<AppBar className={classes.navBar} position='static'>
				<div>
					<ul className='HomePageNav'>
						<li>
							<a href='/'>HOME</a>
						</li>
						<li>
						<Button color="primary" onClick={handleClickOpen} className={classes.aboutButton}>
							Acknowledgement
						</Button>
						</li>
					</ul>
				</div>
				<Toolbar className={classes.toolbar}>
					{props.themeName ? (
						<Button variant='contained' className={classes.icon}>
							{props.themeName.title}
						</Button>
					) : (
						''
					)}
					<div className={classes.search}>
						<div className={classes.searchIcon}>
							<Tooltip cursor='pointer' title='Go Back to Home Page'>
								<Link to={`/articleList/${props.data.theme}`}>
									<div>
										<IconButton aria-label='Go back to Home Page'>
											<ArrowBackIcon className={classes.backIcon} />
										</IconButton>
									</div>
								</Link>
							</Tooltip>
						</div>
						<Typography
							dangerouslySetInnerHTML={{ __html: props.data.title }}
							variant='h6'
							color='inherit'
							className={classes.articleName}
						/>
					</div>
				</Toolbar>
				<Dialog
					aria-labelledby="alert-dialog-title"
					open={open}
					onClose={handleClose}
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">Acknowledgement</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							This database was financially supported by the Research Grant from The Open University of Hong Kong (R6281).
						</DialogContentText>
					</DialogContent>
				</Dialog>
			</AppBar>
		</div>
	);
}
