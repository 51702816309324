import React from 'react';
import Typography from '@material-ui/core/Typography';
export default function YoutubeVideoCard(props) {
	return (
		<>
			<iframe
				title="article iframe"
				id="ytplayer"
				type="text/html"
				width="100%"
				height="360"
				src={`https://www.youtube.com/embed/${props.data.videoID}`}
				frameBorder="0"
			/>
			<Typography
				gutterBottom
				variant="body2"
				color="textSecondary"
				component="p">
				{props.data.caption}
			</Typography>
		</>
	);
}
