import React, { useEffect } from 'react';
import HomePageDrawer from '../components/HomePage/HomePageDrawer';

import axios from 'axios';

function HomePage({ history }) {
	const [data, setData] = React.useState(null);

	useEffect(() => {
		document.title = 'Nutrient Information Inquiry - funded by OUHK';
		async function fetchData() {
			const result = await axios.get(
				`${process.env.REACT_APP_API_URL}/v1/articles`
			);
			setData(result.data);
		}
		fetchData();
	}, []);

	return (
		<div className='HomePage'>
			<HomePageDrawer history={history} data={data} />
		</div>
	);
}

export default HomePage;
