import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(3),
		overflowX: 'auto'
	},
	table: {
		minWidth: 50,
		//
		width: '100%',
		marginTop: theme.spacing(3),
		overflowX: 'auto',
		marginBottom: theme.spacing(3)
	}
}));

export default function ChemistryCardTable({ data }) {
	function createData(rowOne) {
		return { rowOne };
	}
	const rows = [];
	const tableData = data.split('\n');
	tableData.shift();
	tableData.forEach((data, id) => {
		if (data[0] === '-') {
			data = data.slice(1, data.length);
		}
		rows.push(createData(data.trim()));
	});
	const classes = useStyles();
	return (
		<Table size="small" className={classes.table}>
			<TableHead>
				<TableRow>
					<TableCell>Per 100ml</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map((row, id) => (
					<TableRow key={id}>
						<TableCell component="th" scope="row">
							{row.rowOne}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}
