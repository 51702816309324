import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GridList } from '@material-ui/core';
import HomeRecentCard from './RecentItemCard';

const useStyles = makeStyles(theme => ({
	root: {
		margin: '10px auto',
		maxWidth: '936px',
		borderRadius: '4px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper
	},
	gridListTile: {
		cursor: 'pointer'
	},
	gridList: {
		overflow: 'hidden !important',
		margin: '10px !important',
		height: '100%',
		justifyContent: 'center',

		// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
		transform: 'translateZ(0)'
	},
	titleBar: {
		background:
			'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
			'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
	},
	icon: {
		color: 'white'
	},
	gridImage: {
		transform: 'none !important',
		left: 'auto !important',
		margin: 'auto'
	}
}));

export default function HomeRecentItems({ data, history }) {
	const newData = data ? data.filter((data, id) => id % 15 === 0) : [];
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<GridList cellHeight={200} spacing={1} className={classes.gridList}>
				{newData.map((tile, id) => (
					<HomeRecentCard key={tile.id} articleID={tile.id} />
				))}
			</GridList>
		</div>
	);
}
