import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";

import axios from "axios";

const useStyles = makeStyles(theme => ({
	card: {
		display: "flex",
		margin: "5px",
		width: "100%"
	},
	details: {
		display: "flex",
		flexDirection: "column",
		flexBasis: "70%"
	},
	content: {
		flex: "1 0 auto",
		textAlign: 'left !important'
	},
	cover: {
		flexBasis: "30%"
	},
	heading:{
		color: 'hsl(0, 0%, 13%)',
		fontWeight: '700',
		marginBottom: '5px'
	},
	greyText:{
		color: 'hsl(0, 0%, 29%)',
		fontWeight: '500'
	},
	lightGreyText:{
		color: 'hsl(0, 0%, 45%)',
		fontWeight: '400'
	}
}));

export default function RecentItemMobileCard({ articleID }) {
	const [data, setData] = useState({});
	const classes = useStyles();

	useEffect(() => {
		async function fetchData() {
			const result = await axios.get(
				`${process.env.REACT_APP_API_URL}/v1/articles/${articleID}`
			);
			setData(result.data);
		}
		fetchData();
	}, [articleID]);
	if (data.content) {
		const tableData = data.content[5].content.split("\n");
		tableData.shift();
		let cleanData = tableData.map((nutritionData, id) => {
			if (nutritionData[0] === "-") {
				nutritionData = nutritionData.slice(1, nutritionData.length);
			}
			if (id % 2 === 0 && id < 6) {
				return nutritionData.trim();
			}
		});
		data.content[5].content = cleanData.join(" ");
	}

	return (
		<Card className={classes.card}>
			<CardMedia
				alt={data.title}
				className={classes.cover}
				image={data.image}
				title={data.title}
			/>
			<div className={classes.details}>
				<CardContent className={classes.content}>
					<Typography
						className={classes.heading}
					>
						{data.title}
					</Typography>
					<Typography className={classes.greyText}>
						{data.brand}
					</Typography>
					<Typography className={classes.lightGreyText}>
						{data.content ? data.content[5].content : ""}
					</Typography>
				</CardContent>
			</div>
		</Card>
	);
}
