import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { List, ListItem, GridList, Typography } from '@material-ui/core';

import ArticleCard from './articleCards';

const useStyles = makeStyles(theme => ({
	link: {
		textDecoration: 'none',
		color: 'inherit'
	},
	notFoundText: {
		...theme.typography.button,
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(1)
	},
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper
	},
	gridList: {
		width: '100%',
		height: 'auto',
		marginTop: '10px !important',
		justifyContent: 'flex-start',
		cursor: 'pointer',
		margin: '10px auto !important',
		maxWidth: '936px',
		borderRadius: '4px',
		paddingLeft: '8px',
		minHeight: 'calc(100vh - 260px)',
	},
	icon: {
		color: 'rgba(255, 255, 255, 0.54)'
	},
	personalRoot: {
		flexBasis: '32%',
		margin: '2px 3px !important'
	},
	personalImage: {
		backgroundSize: 'contain'
	}
}));

export default function ChemistryArticleGrid({ data, searchWord }) {
	const classes = useStyles();
	const [articles, setArticles] = useState({});

	useEffect(() => {
		const newData = data.filter(({ brand, title, tags }) => {
			return (
				brand.toLowerCase().includes(searchWord) ||
				title.toLowerCase().includes(searchWord) ||
				tags.join(' ').toLowerCase().includes(searchWord)
			);
		});
		setArticles(newData);
	}, [data, searchWord]);


	return (
		<GridList cellHeight={180} className={classes.gridList}>
			{articles[0] ? (
				articles.map(article => {
					return <ArticleCard data={article} key={article.id}></ArticleCard>;
				})
			) : (
				<List>
					<ListItem alignItems='flex-start'>
						<Typography className={classes.notFoundText} color='inherit'>
							NO ARTICLES FOUND
						</Typography>
					</ListItem>
				</List>
			)}
		</GridList>
	);
}
