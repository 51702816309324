import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Dashboard from '../pages/Dashboard';
import ArticleList from '../pages/ArticleList';
import HomePage from '../pages/HomePage';

const Routers = () => (
	<BrowserRouter>
		<Switch>
			<Route path="/article/:id" component={Dashboard} />
			<Route exact path="/articleList" component={ArticleList} />
			<Route exact path="/" component={HomePage} />
			<Route path="/articleList/:id" component={ArticleList} />
		</Switch>
	</BrowserRouter>
);

export { Routers };
