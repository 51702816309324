import React, { useState, useEffect } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	AppBar,
	Button,
	Hidden,
	List,
	Divider,
	Drawer,
	ListItem,
	ListItemText,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
} from '@material-ui/core';

import HomePageSearch from './SelectSearch';

const useStyles = makeStyles(theme => ({
	grow: {
		marginBottom: '10px'
	},
	root: {
		width: '100%',
		backgroundColor: '#333',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	search: {
		margin: '5px auto',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#d32323',
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '100%'
		}
	},
	searchIcon: {
		width: theme.spacing(9),
		height: '100%',
		margin: 'auto 0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	backIcon: {
		color: '#fff'
	},
	copyAndBackIcon: {
		width: theme.spacing(12),
		height: '100%',
		margin: 'auto 0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	inputRoot: {
		color: 'inherit',
		flexGrow: 3
	},
	inputInput: {
		height: '100%',
		padding: 0,
		color: 'grey',
		borderRadius: '1px',
		background: 'white',
		textAlign: 'center',
		// padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '100%'
		}
	},
	toolbar: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '5px'
	},
	icon: {
		margin: 'auto',
		marginTop: '10px',
		marginBottom: '5px !important',

	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		},
		flexBasis: '20%'
	},
	drawerPaper: {
		width: '240px',
		flexShrink: 0
	},
	drawer: {
		width: '240px'
	},
	articleListNav: {
		display: 'flex',
		justifyContent: 'space-around',
		width: '100%'
	},
	aboutButton: {
		color: 'white'
	},
	searchBar: {
		maxWidth: 1000,
		width: '100%'
	}
}));

export default function ChemistrySearchBar(props) {
	const { container } = props;
	const classes = useStyles();
	const theme = useTheme();

	const [searchParam, setSearch] = useState(props.searchParams || '');
	const [disable, setDisable] = useState(true);

	const [mobileOpen, setMobileOpen] = React.useState(false);

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
    setOpen(true);
	};
	const handleClose = () => {
    setOpen(false);
  };

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleChange = e => {
		if (e.target.value === '') {
			setDisable(true);
		} else {
			setDisable(false);
		}
		props.search(e.target.value);
		setSearch(e.target);
	};

	useEffect(() => {
		if (props.searchParams && !searchParam) {
			props.search(props.searchParams);
			setDisable(false);
		}
	}, [props, searchParam]);

	function onThemeChange(themeid) {
		if (props.onThemeChange) {
			props.onThemeChange(themeid);
		}
	}

	return (
		<AppBar className={classes.root} position="fixed">
			<div>
				<ul className='HomePageNav'>
					<li>
						<a href='/'>Home</a>
					</li>
					<li>
						<Button className={classes.aboutButton} onClick={handleClickOpen}>
							Acknowledgement
						</Button>
					</li>
				</ul>
			</div>
			<div>
				<Hidden smUp implementation='js'>
					<nav className={classes.drawer} aria-label='nav'>
						<Drawer
							container={container}
							variant='temporary'
							anchor={theme.direction === 'rtl' ? 'right' : 'left'}
							open={mobileOpen}
							onClose={handleDrawerToggle}
							classes={{
								paper: classes.drawerPaper
							}}
							ModalProps={{
								keepMounted: true // Better open performance on mobile.
							}}>
							<div>
								<div className={classes.toolbar} />
								<Divider />
								<List>
									<ListItem button component="a" href="/">
										<ListItemText primary="Home" />
									</ListItem>
									<ListItem button onClick={handleClickOpen}>
										<ListItemText primary="Acknowledgement" />
									</ListItem>
								</List>
							</div>
						</Drawer>
					</nav>
				</Hidden>
			</div>
			<Dialog
				aria-labelledby="alert-dialog-title"
				open={open}
				onClose={handleClose}
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Acknowledgement</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						This database was financially supported by the Research Grant from The Open University of Hong Kong (R6281).
					</DialogContentText>
				</DialogContent>
			</Dialog>
			<HomePageSearch search={e => handleChange(e)} searchParam={searchParam} themeid={parseInt(props.themeid)} onThemeChange={onThemeChange} className={classes.searchBar}/>
		</AppBar>
		// </div>
	);
}
