import React from 'react';
import {
	AppBar,
	Typography,
	CssBaseline,
	Divider,
	Drawer,
	Toolbar,
	Hidden,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import HomePageHeader from './HomePageHeader';
import HomePageCategory from './GridList';
import HomePopularSearch from './PopularSearches';
import HomeRecentItems from './RecentItemsGrid';
import HomePageFooter from './HomeFooter';
import ResultsPopper from './ResultsPopper';
import HomeRecentItemsMobile from './RecentItemsMobileGrid';

const useStyles = makeStyles(theme => ({
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		},
		flexBasis: '20%'
	},
	iconButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		},
		flexBasis: '80%'
	},
	subsectionHeading: {
		color: '#d32323',
		margin: '15px auto 10px auto'
	},
	appBar: { backgroundColor: '#333' },
	// toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	navHeader: {
		display: 'flex',
		justifyContent: 'space-around',
		backgroundColor: '#333'
	},
	navHeadings: {
		margin: '0px 30px'
	},
	navToolbar: {
		display: 'flex',
		justifyConten: 'space-around'
	},
	drawerPaper: {
		width: '240px',
		flexShrink: 0
	},
	drawer: {
		width: '240px'
	},
	title: {
    flexGrow: 1,
	},
	offset: {
		minHeight: 120
	},
}));

function HomePageDrawer(props) {
	const { container, data, history } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);


	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
    setOpen(true);
	};
	const handleClose = () => {
    setOpen(false);
  };

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	let popularTags = [];
	if (data) {
		data.forEach((article, id) => {
			article.tags.forEach(tag => {
				if (!popularTags.includes(tag) && id % 5 === 0 && tag !== "" && popularTags.length <= 7) {
					popularTags.push(tag);
				}
			});
		});
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			{/* <div> */}
			<Hidden smUp implementation='js'>
				<AppBar position='fixed' className={classes.appBar}>
					<Toolbar className={classes.navHeader}>
						<IconButton
							color='inherit'
							aria-label='open drawer'
							edge='start'
							onClick={handleDrawerToggle}
							className={classes.menuButton}>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							Nutrient Information Inquiry
						</Typography>
					</Toolbar>
					<div className='HomePageSearch' class>
						<ResultsPopper data={data} />
					</div>
				</AppBar>
				<nav className={classes.drawer} aria-label='nav'>
					<Drawer
						container={container}
						variant='temporary'
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}>
						<div>
							<div className={classes.toolbar} />
							<Divider />
							<List>
								<ListItem button component="a" href="/">
									<ListItemText primary="Home" />
								</ListItem>
								<ListItem button onClick={handleClickOpen}>
									<ListItemText primary="Acknowledgement" />
								</ListItem>
							</List>
							<Divider />
							<Typography
								className={classes.subsectionHeading}
								align='center'
								variant='h6'
								noWrap>
								Category
							</Typography>
							<List>
								{[
									'Juice',
									'Instant drink',
									'Tea',
									'Soft drink',
									'Energy drink',
									'Dairy Products'
								].map((text, index) => (
									<ListItem button key={index}>
										<ListItemText primary={text} />
									</ListItem>
								))}
							</List>
							<Divider />
							<Typography
								className={classes.subsectionHeading}
								align='center'
								variant='h6'
								noWrap>
								Popular Tags
							</Typography>
							<List>
								{popularTags.map((text, index) => (
									<ListItem button key={index}>
										<ListItemText primary={text} />
									</ListItem>
								))}
							</List>
						</div>
					</Drawer>
				</nav>
			</Hidden>
			<Dialog
				aria-labelledby="alert-dialog-title"
				open={open}
				onClose={handleClose}
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Acknowledgement</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						This database was financially supported by the Research Grant from The Open University of Hong Kong (R6281).
					</DialogContentText>
				</DialogContent>
			</Dialog>
			<Hidden xsDown implementation='js'>
				<HomePageHeader data={data} />
			</Hidden>
			<main className={classes.content}>
				<Hidden smUp implementation='js'>
					<div className={classes.offset} />
				</Hidden>
				<Typography
					className={classes.subsectionHeading}
					align='center'
					variant='h6'
					noWrap>
					Category
				</Typography>
				<HomePageCategory history={history} />
				<HomePopularSearch data={popularTags} />
				<Typography
					className={classes.subsectionHeading}
					align='center'
					variant='h6'
					noWrap>
					Recent
				</Typography>
				<Hidden xsDown implementation='js'>
					<HomeRecentItems history={history} data={data} />
				</Hidden>
				<Hidden smUp implementation='js'>
					<HomeRecentItemsMobile history={history} data={data} />
				</Hidden>
			</main>
			<Hidden xsDown implementation='js'>
				<HomePageFooter popularTags={popularTags} />
			</Hidden>
		</div>
	);
}

export default HomePageDrawer;
