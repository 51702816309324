import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	Typography
} from '@material-ui/core';

import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		maxWidth: 1200,
		minHeight: 'calc(100vh - 64px)',
		overflowY: 'scroll',
		boxSizing: 'border-box'
	},
	paper: {
		padding: theme.spacing(3, 2),
		margin: 'auto',
		maxWidth: 1200,
		marginTop: '10px'
	},
	link: {
		textDecoration: 'none',
		color: 'inherit'
	},
	chip: {
		margin: '4px 4px 4px 0'
	},
	notFoundText: {
		...theme.typography.button,
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(1)
	}
}));

export default function ChemistryList({ data, searchWord }) {
	const classes = useStyles();
	const [articles, setArticles] = useState({});

	useEffect(() => {
		if (data !== null) {
			const newData = data.filter(({ brand, title, tags }) => {
				return (
					brand.toLowerCase().includes(searchWord) ||
					title.toLowerCase().includes(searchWord) ||
					tags.join(' ').toLowerCase().includes(searchWord)
				);
			});
			setArticles(newData);
		}
	}, [data, searchWord]);

	return (
		<List component='nav' className={classes.root}>
			{articles[0] ? (
				articles.map(article => {
					return (
						<Link
							key={article.id}
							className={classes.link}
							to={`/article/${article.id}`}>
							<ListItem alignItems='flex-start' key={article.id}>
								<ListItemAvatar>
									<Avatar src={article.image} />
								</ListItemAvatar>
								<div>
									<Typography
										dangerouslySetInnerHTML={{ __html: article.title }}
										color='inherit'
									/>
									<Typography
										variant='subtitle1'
										align='left'
										color='textSecondary'
										component='p'>
										{article.brand}
									</Typography>
								</div>
							</ListItem>
						</Link>
					);
				})
			) : (
				<ListItem alignItems='flex-start'>
					<Typography className={classes.notFoundText} color='inherit'>
						NO ARTICLES FOUND
					</Typography>
				</ListItem>
			)}
		</List>
	);
}
