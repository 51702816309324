import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		padding: theme.spacing(0.5),
		maxWidth: 590,
		margin: 'auto',
		marginTop: 20
	},
	chip: {
		margin: theme.spacing(0.5),
		backgroundColor: '#333',
		color: 'white'
	}
}));

export default function ChemistryTags(props) {
	const [chipData, setChipData] = React.useState([{ key: 0, label: 'No Tags' }]);
	// console.log('tags', props.data);
	const classes = useStyles();
	// let tagData = [{ key: 0, label: 'No Tags' }];

	useEffect(() => {
		if (props.data) {
			const tagData = props.data.map((tag, id) => {
				console.log(tag);
				return {
					key: id,
					label: tag
				};
			});
			setChipData(tagData)
		}
	}, [props.data]);

	return (
		<Paper className={classes.root}>
			{chipData.map(data => {
				return (
					<Chip key={data.key} label={data.label} className={classes.chip} />
				);
			})}
		</Paper>
	);
}
