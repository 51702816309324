import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Card, CardActionArea, CardContent, CardMedia, Typography} from '@material-ui/core';



const useStyles = makeStyles({
	card: {
		maxWidth: '32%',
		margin: '5px',
		flexBasis: '32%',
	},
	image: {
		backgroundSize: 'cover',
		objectFit: 'contain'
	},
	cardLink:{
		textDecoration: 'none',
		color: 'inherit'
	}
});

export default function ArticleCard({ data }) {
	const classes = useStyles();

	return (
		<Card className={classes.card}>
			<a className={classes.cardLink} href={`/article/${data.id}`}>
			<CardActionArea>
				<CardMedia
					component='img'
					alt={data.title}
					height='140'
					classes={{
						media: classes.image
					}}
					image={data.image}
					title={data.title}
				/>
				<CardContent>
					<Typography component='h4'>{data.title}</Typography>
					<Typography variant='subtitle1' color='textSecondary'>
						{data.brand}
					</Typography>
				</CardContent>
			</CardActionArea>
			</a>
		</Card>
	);
}
