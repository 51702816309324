import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import {
	FormControl,
	Select,
	MenuItem,
	InputBase,
	Typography,
	Button
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
	},
	subsectionHeading: {
		color: '#d32323',
		margin: '15px auto 10px auto',
		display: 'block'
	},
	header: {
		margin: '10px auto',
		maxWidth: '936px',
		borderRadius: '4px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5)
		}
	}
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
		fontSize: 16,
		width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}))(InputBase);

export default function HomePopularSearch({ data }) {

	const classes = useStyles();

	const [categoryFilter, setCategory] = useState(0);
	const [TagFilter, setTag] = useState('energy free');

	const handleChangeCategory = e => {
		setCategory(e.target.value);
	};

	const handleChangeTag = e => {
		setTag(e.target.value);
	};

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<Typography
					className={classes.subsectionHeading}
					align='center'
					variant='h6'
					noWrap>
					Popular Tags
				</Typography>
			</div>
			<div className={classes.header}>
				<FormControl className={classes.margin}>
					<Select
						labelId="demo-customized-select-label"
						id="demo-customized-select"
						value={TagFilter}
						onChange={handleChangeTag}
						input={<BootstrapInput />}
					>
						{data.map((tag, id) => {
							return (
								<MenuItem
									value={tag}
									key={id}
								>
									{tag}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<Typography
					align='center'
					variant='h6'
					noWrap>
					In
				</Typography>
				<FormControl className={classes.margin}>
					<Select
						labelId="demo-customized-select-label"
						value={categoryFilter}
						onChange={handleChangeCategory}
						id="demo-customized-select"
						input={<BootstrapInput />}
					>
						<MenuItem value={0}>
							<em>All categories</em>
						</MenuItem>
						<MenuItem value={7}>Juice</MenuItem>
						<MenuItem value={8}>Instant drink</MenuItem>
						<MenuItem value={9}>Tea</MenuItem>
						<MenuItem value={10}>Soft drink</MenuItem>
						<MenuItem value={11}>Energy drink</MenuItem>
						<MenuItem value={12}>Dairy products</MenuItem>
					</Select>
				</FormControl>
				<Button variant="outlined" color="primary" href={`/articleList/${categoryFilter || ''}?search=${TagFilter}`}>
					Go
				</Button>
			</div>
			<div className={classes.header}>
				{data.map((tag, id) => {
					return (
						<Chip
							label={tag}
							key={id}
							component='a'
							href={`/articleList/${categoryFilter || ''}?search=${tag}`}
							clickable
							variant='outlined'
						/>
					);
				})}
			</div>
		</div>
	);
}
