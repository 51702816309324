import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import axios from 'axios';

const useStyles = makeStyles({
	card: {
		maxWidth: '32%',
		margin: '5px'
	},
	cardLink: {
		textDecoration: 'none',
		color: 'inherit'
	},
	heading:{
		color: 'hsl(0, 0%, 13%)',
		fontWeight: '700'
	},
	greyText:{
		color: 'hsl(0, 0%, 29%)',
		fontWeight: '500'
	},
	lightGreyText:{
		color: 'hsl(0, 0%, 45%)',
		fontWeight: '400'
	}
});

const cardImageStyle = {
	objectFit: 'contain',
};

export default function HomeRecentCard({ articleID }) {
	const [data, setData] = useState({});

	const classes = useStyles();

	useEffect(() => {
		async function fetchData() {
			const result = await axios.get(
				`${process.env.REACT_APP_API_URL}/v1/articles/${articleID}`
			);
			setData(result.data);
		}
		fetchData();
	}, [articleID]);

	if (data.content) {
		const tableData = data.content[5].content.split('\n');

		tableData.shift();
		let cleanData = tableData.map((nutritionData, id) => {
			if (nutritionData[0] === '-') {
				nutritionData = nutritionData.slice(1, nutritionData.length);
			}
			if (id % 2 === 0 && id < 6) {
				return nutritionData.trim();
			}
		});
		data.content[5].content = cleanData.join(' ');
	}

	return (
		<Card className={classes.card}>
			<a className={classes.cardLink} href={`/article/${articleID}`}>
				<CardActionArea>
					<CardMedia
						component='img'
						alt={data.title}
						height='140'
						image={data.image}
						title={data.title}
						style={cardImageStyle}
					/>
					<CardContent>
						<Typography gutterBottom className={classes.heading} >
							{data.title}
						</Typography>
						<Typography gutterBottom className={classes.greyText} >
							{data.brand}
						</Typography>
						<Typography className={classes.lightGreyText} >
							{data.content ? data.content[5].content : ''}
						</Typography>
					</CardContent>
				</CardActionArea>
			</a>
		</Card>
	);
}
