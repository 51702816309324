import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
	header: {
		marginTop: '12px',
		marginBottom: '8px',
	}
}));

export default function ChemistryCardHeader(props) {
	const classes = useStyles();
	return (
		<Typography className={classes.header} gutterBottom align="left" variant="h5" component="h2">
			{props.data}
		</Typography>
	);
}
